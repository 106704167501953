.cart-icon {
  position: relative;
  height: 1.2em;
  width: 1.2em !important;
}
.count-badge {
  position: absolute;
  top: -8px;
  right: 8px;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.location-icon {
  height: 0.8em;
  width: 1.2em !important;
}

#responsive-navbar-nav {
  width: 100%; /* Default width for small screens */
  top: auto;
  left: 10px;
}

/* Styles for screen sizes larger than the md breakpoint (768px) */
@media (min-width: 992px) {
  #responsive-navbar-nav {
    width: 45%;
  }
}
@media (min-width: 1200px) {
  #responsive-navbar-nav {
    width: 40%;
  }
}
@media (min-width: 1600px) {
  #responsive-navbar-nav {
    width: 35%;
  }
}
