.indexedStep {
    color: #4a5073;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: #65ac4b;
    color: white;
  }
  .RSPBprogressBar .RSPBprogression {
    background:#65ac4b!important
  }