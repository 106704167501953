.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    @include transition(height .3s);

    .loader-element {
        @include transition(transform .3s linear infinite);
        -webkit-animation: blink 1.5s ease-in-out infinite;
        -moz-animation: blink 1.5s ease-in-out infinite;
        animation: blink 1.5s ease-in-out infinite;
    }

    &.show {
        height: 0;

        .loader-element {
            display: none !important;
        }
    }
}

@-moz-keyframes blink { 
    0%, 100% { 
        -moz-transform: scale(1); 
    } 
    50% { 
        -moz-transform: scale(1.5); 
    } 
}
@-webkit-keyframes blink { 
    0%, 100% { 
        -webkit-transform: scale(1); 
    } 
    50% { 
        -webkit-transform: scale(1.5); 
    } 
}
@keyframes blink { 
    0%, 100% { 
        -webkit-transform: scale(1); 
        transform: scale(1); 
    } 
    50% { 
        -webkit-transform: scale(1.5); 
        transform: scale(1.5); 
    } 
}
