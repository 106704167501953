.custom-card {
  height: 28rem;
}
.blog-card {
  height: 25rem;
}
.product-card {
  height: 23rem;
  object-fit: contain;
  position: relative;
}
.card-image {
  object-fit: contain;
  height: 43%;
}
.card-image img {
  height: 100%;
  object-fit: contain;
}
.gallery-card {
  height: 10rem;
}
.expiry-alert {
  animation: alert 1.5s ease-in-out infinite;
}

@keyframes alert {
  0% {
    color: #f74b4bf2;
    text-shadow: 0 0 10px #f74b4bf2;
    backdrop-filter: blur(5px);
  }
  50% {
    color: #bb4040f2;
    text-shadow: 0 0 20px #c44141f2;
    backdrop-filter: blur(10px);
  }
  100% {
    color: #883030f2;
    text-shadow: 0 0 10px #a73838f2;
    backdrop-filter: blur(5px);
  }
}
.activate-link-text {
  position: relative;
}
.activate-link-text::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #0948b3;
  transition: width 0.3s ease;
}

.activate-link-text:hover::before,
.activate-link-text.underline::before {
  width: 100%;
}

/* .btn-check:focus + .btn, .btn:focus, .btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
    color: #ffffff;
    box-shadow: none;
  } */
.verified-badge {
  position: absolute;
  right: -5px;
  top: 120px;
}
.title {
  min-height: 35px;
  font-size: 0.9rem;
  color: #494a4b;
  font-weight: 700;
}
