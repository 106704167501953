@font-face {
  font-family: "Engschrift";
  src: url("./assets/fonts/Engschrift.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.dark-green {
  color: #132c0a;
}
.green-color {
  color: #65ac4b;
}
.black-color {
  color: #555;
}
.black-color:hover {
  color: #65ac4b;
}
.light-green-color {
  color: #90dfdf;
}

.green-btn {
  background-color: #65ac4b;
  border: none;
  color: #fff;
  /* width:20rem */
}

.screen-height {
  height: 60vh;
}

.business-link:hover {
  text-decoration: underline;
}
.business-link {
  text-decoration: none;
  color: #65ac4b !important;
}
.link-text {
  text-decoration: none;
  color: #000000b8;
  position: relative;
  width: max-content;
}
.link-text:hover {
  color: #65ac4b;
}

.link-text::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px; /* Adjust this value to control the distance between text and underline */
  width: 0; /* Start with no width */
  height: 2px; /* Set the height of the underline */
  background-color: #65ac4b; /* Set the color of the underline */
  transition: width 0.3s ease; /* Add transition for smooth animation */
}

.link-text:hover::before,
.link-text.underline::before {
  width: 100%;
}

.bg-green {
  background-color: #65ac4b !important;
  border: none;
}

/* font weight */
.fontweigh-800 {
  font-weight: 800;
}
.fontweigh-500 {
  font-weight: 500;
}

.fontweigh-700 {
  font-weight: 700;
}

/*  width */
.w-16 {
  width: 16rem;
}
.w-10 {
  width: 10rem;
}

/* font-size */
.fs-8 {
  font-size: 0.8rem;
}

.cursor-pointer {
  cursor: pointer;
}

.fit-content {
  max-inline-size: fit-content;
}

.link-btn:focus,
.link-btn:active,
.link-btn:focus-visible,
.link-btn:active:focus {
  outline: 0;
  box-shadow: none;
}
.form-control:focus {
  border-color: #fff;
}
.loader-back {
  background-color: #f5f8fba6 !important;
}
.pac-container {
  z-index: 1100 !important;
}
.min-height {
  min-height: 12em;
}
.custom-switch .form-check-input:checked {
  background-color: #65ac4b;
  border-color: #65ac4b;
  cursor: pointer;
}
.custom-switch {
  cursor: pointer;
}

body {
  font-family: "Poppins", sans-serif;
}

.ad-card-body ul li {
  list-style: none;
  padding-right: 0;
  position: relative;
  padding-left: 18px;
}
.ad-card-body ul li:before {
  position: absolute;
  content: "✔";
  left: 0;
  top: 0;
  color: #65ac4b;
  font-weight: 600;
}

.first-col {
  display: flex;
  flex-direction: column;
}

.full-height-image {
  height: 100%;
  object-fit: cover;
}

.green-status {
  width: 12px;
  height: 12px;
  background-color: #65ac4ba1;
  border-radius: 50%;
  display: inline-block;
}
.red-status {
  width: 12px;
  height: 12px;
  background-color: #dd0a0aa1;
  border-radius: 50%;
  display: inline-block;
}
.brown-status {
  width: 12px;
  height: 12px;
  background-color: #972d0da1;
  border-radius: 50%;
  display: inline-block;
}

/* live green dot */
.live-status {
  width: 12px;
  height: 12px;
  background-color: #65ac4ba1;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.8);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 255, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.8);
  }
}

/* caresoule Arrow */
.gallery-slider .slick-prev:before,
.gallery-slider .slick-next:before {
  /* color:#333;
  font-size: 30px;  */
  display: none;
}
.card-slider .slick-prev:before,
.card-slider .slick-next:before {
  /* color:#333;
  font-size: 33px; */
  display: none;
}
.slick-dots li button:before {
  color: #65ac4b !important;
}
/* .card-slider .slick-next {
  right: -18px;
}
.card-slider .slick-prev {
  left: -35px;
} */

.loading-data {
  text-align: center;
  align-content: center;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    color: #049b9bc2;
  }
  50% {
    color: #057474c2;
  }
  100% {
    color: #053838c2;
  }
}

.page-container {
  background-color: #65ac4b47;
}

.table > thead {
  background-color: #d3d3d37d;
}

/* Verified dispensaries */
.image-card {
  background-size: cover;
  background-position: center;
  height: 16em;
  transition: transform 0.3s ease;
  margin: 1em;
  margin-bottom: 20px;
  position: relative;
}

.image-card:hover {
  transform: translateY(
    -10px
  ); /* Increase the scale on hover to create a zoom effect */
}

.disp-image {
  position: relative;
  width: 100%;
  height: 130px;
  background-size: cover;
  background-position: center;
  /* cursor: pointer; */
}
.content-left {
  position: absolute;
  bottom: 15%;
  left: 5%;
  width: 100%;
  border-radius: 50%;
}
.content-left p,
img {
  color: #fff;
  font-weight: 800;
}

/* short description */
.short-description ul {
  padding-left: 20px;
}

.disabled {
  background-color: #262b4012 !important;
}
.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus,
.btn:active:focus,
.btn.active:focus {
  box-shadow: none;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
/* Shop by Category */
.cat-card {
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: #65ac4b00;
}

.cat-card-image {
  border-radius: 50%;
  box-shadow: 0px 0px 6px 6px #0000001a;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 1em 0;
}
.cat-card:hover .cat-card-image {
  transform: scale(1.1);
}
.cat-card-heading {
  color: #333;
  font-weight: 500;
  text-decoration: underline;
  padding: 0;
}

.coupon-badge {
  position: absolute;
  top: 0;
  right: 0;
}

/* z-index */
.page-item.active .page-link {
  z-index: 0;
}
.accordion-button:focus {
  z-index: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 0;
}

.water-ripple-button {
  position: relative;
  overflow: hidden;
  background-color: #2a3028;
  color: #000;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
}

.water-ripple-button::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  background: #485244;
  animation: wave-ripple 2s infinite;
  pointer-events: none;
}

@keyframes wave-ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

/* Shop by categories */
.slick-slide img {
  display: inline !important;
}
/* modification of general class */
.btn-outline-success:hover {
  color: #fff;
}
