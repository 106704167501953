.top-line{
color:#015e5e;
height: 5px !important;
}
.footer-image{
    height: 20rem;
}
.footer-card{ 
    border:none; 
    background-color:inherit ;
}
.privacy-table .table td, .privacy-table .table th {
   
    white-space: normal!important;
}
.footer-accordion .accordion-button:not(.collapsed)::after {
    background-image:url(../../../assets/img/minus-image.png)
    
}
.footer-accordion .accordion-button::after {
background-image: url("../../../assets/img/plus-image.png");
}
.accordion-button:focus {
    border: 0.0625rem solid rgba(46, 54, 80, 0.125);
    box-shadow: none;
}
.accordion-collapse {
    border-width: 0.0625rem;
}
.accordion-button.collapsed {
    border-bottom-width: 1px;
}