.dropdown-menu-notifications {
    max-height: 80vh;
    overflow-y: auto;
    white-space: normal; /* Allows text to wrap */
    padding:20px;
  }
  
  .wide-dropdown-menu {
    width: 35vw;
    margin-top: 5px;
  } 
  @media (max-width:1200px) {
    .wide-dropdown-menu {
        width: 45vw;
      }
 }
  @media (max-width:800px) {
    .wide-dropdown-menu {
        width: 50vw;
      }
 }
 @media (max-width:600px) {
    .wide-dropdown-menu {
        width: 80vw;
      }
 }
 @media (max-width:500px) {
    .wide-dropdown-menu {
        width: 75vw;
      }
 }
  
  .notification-item {
    white-space: normal; /* Allows text to wrap */
    word-break: break-word; /* Ensures long words break to fit the container */
    /* padding:5px!important */
  }
  
  .notification-item.unread {
     background-color: #f8f9fa; /* Light background for unread notifications */
     color: #65ac4b;
  }
  
  .notification-item.read {
    font-weight: normal;
    background-color: #ffffff; /* White background for read notifications */
  }
  
  .notification-item:hover {
    background-color: #f1f1f1;
  }
  
  .notification-badge {
    position: relative;
    top: -10px!important;
  }
  
  .dropdown-menu span {
    width: auto;
  }
  .notification-bell .btn-light .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus{
    box-shadow: none;
  }
  .notification-bell .btn-light{
    box-shadow:none;
    padding:10px
  }
  .bell-icon{
    height:1.2em;
    width:1.2em!important;
  }
  .icon-cover{
    width:35px
  }